:root {
  --bodyBackground: #000015;
  --bgColor: #000015;
  --showSocial: flex;
  --socialPosition: relative;
  --zIndexMenu: 9;
}

body,
html {
  font-family: "Biko";
  font-size: 12px;
  color: #fff;
  cursor: none;
  max-height: -webkit-fill-available !important;
}

body {
  height: 100vh !important;
  background: none;
}

html {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-attachment: fixed !important;
  background: var(--bodyBackground);
  background-size: cover !important;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 50px;
}

p {
  font-size: 32px;
  margin-bottom: 30px;
}

a {
  color: #fff;
}

a:visited {
  color: #fff;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

*:focus {
  outline: none;
}

.cursor {
  position: fixed;
  top: -30px;
  left: -30px;
  background-color: white;
  width: 20px;
  height: 20px;
  z-index: 999;
  border-radius: 10px;
  overflow: hidden;
  margin-top: -10px;
  margin-left: -10px;
  pointer-events: none;
  mix-blend-mode: difference;
  pointer-events: none;
}

.container-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100dvh - 97px - 90px);
  height: auto;
  width: 100vw;
}

.card-font {
  font-family: "Cardo";
}

.card-font-italic {
  font-family: "Cardo-Italic";
}

.br-txt-mobile {
  display: none;
}

.show-mobile {
  display: none;
}

.show-desktop {
  display: block;
}

.no-visible {
  display: none;
}

@supports (-webkit-touch-callout: none) {
  .container-section, .container-splash, .container-splash .container-splash-canvas,
  .container-splash .container-splash-texts, .splash-canvas, .container-section-home, .como .row,
  .container-section-work, .container-work-imgs {
    max-height: -webkit-fill-available !important;
  }
}
.container-menu {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 97px;
  padding: 30px 70px;
  z-index: var(--zIndexMenu);
  display: flex;
  justify-content: left;
  align-items: center;
}

.logo-btn {
  display: block;
  z-index: 1;
}

.logo-btn img {
  width: 139px;
}

.burger-btn {
  display: none;
  z-index: 1;
  font-size: 20px;
  margin-left: auto;
}

.burger-btn img {
  width: 24px;
}

.inner-container-menu {
  margin-left: auto;
}

.container-menu ul {
  list-style: none;
  padding-top: 10px;
}

.container-menu ul li {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 108px;
}

.container-menu ul li a {
  font-size: 24px;
  text-decoration: none !important;
}

.container-menu ul li a:hover > img {
  visibility: hidden;
}

.container-menu ul li a img {
  height: 24px;
}

.container-menu .logo {
  width: 70%;
  height: auto;
  max-height: 50%;
}

.como-menu:hover {
  background: url(../images/como-menu-i.svg) center center no-repeat;
}

.work-menu:hover {
  background: url(../images/thework-menu-i.svg) center center no-repeat;
}

.partners-menu:hover {
  background: url(../images/partners-menu-i.svg) center center no-repeat;
}

.send-menu:hover {
  background: url(../images/send-menu-i.svg) center center no-repeat;
}

.close-btn {
  font-size: 32px;
  line-height: 0;
}

.hashtag {
  font-size: 16px;
}

.social {
  display: var(--showSocial);
  position: var(--socialPosition);
  right: 0;
  bottom: 0;
  padding: 30px 70px;
  gap: 18px;
  width: 100vw;
  justify-content: flex-end;
  height: 90px;
}

.social .linkedin {
  margin-left: auto;
}

.social img {
  height: 20px;
}

.container-splash {
  background-color: var(--bgColor);
  z-index: 999;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  opacity: 1;
}

.container-splash .container-splash-canvas {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

.container-splash .container-splash-texts {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

.splash-text {
  font-size: 42px;
  padding: 0px 10px;
  opacity: 0;
  font-family: "Cardo";
}

.splash-canvas {
  width: 100vw;
  width: 100vh;
}

.container-section-home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.como .row {
  height: auto !important;
  align-items: center !important;
}

.como h1 {
  margin-bottom: 40px;
}

.como .diagrama p {
  font-size: 20px;
}

.como .diagrama-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.como .diagrama-img img {
  width: 100%;
}

.como .diagrama-img-1 {
  width: 80% !important;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 40px;
}

.container-section-work {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.container-work-imgs {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
}

.img-one {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

.container-section-work .section-title {
  position: absolute;
  top: 100px;
  left: 70px;
  z-index: 1;
  font-size: 40px;
}

.footer-work {
  position: absolute;
  left: 0;
  bottom: 0;
  height: auto;
  width: 100vw;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  padding-bottom: 0;
}

.footer-work-titles {
  padding: 10px 70px;
}

.footer-work h3 {
  font-size: 38px;
  margin: 0;
  line-height: 20px;
  margin-bottom: 10px;
}

.footer-work h3 span {
  font-size: 24px;
}

.footer-work p {
  font-size: 18px;
  margin: 0;
  line-height: 20px;
}

.footer-work-items {
  display: block;
}

.work-riel-btns {
  display: flex;
  transition: margin-left 0.8s ease-in-out;
}

.footer-work-block {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer-work-item {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 100%;
}

.footer-work-line {
  width: 100%;
  height: 2px;
  background-color: white;
}

.footer-work-line div {
  background: #65167a;
  height: 2px;
}

.footer-work-single-line {
  flex-basis: 100%;
  height: 2px;
  background: white;
  display: none;
}

.footer-work-single-line div {
  background: #65167a;
  height: 2px;
}

.footer-work-single-item {
  padding-top: 30px;
  padding-bottom: 30px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-basis: 100%;
  font-size: 18px;
}

.prev-btn {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  padding-left: 20px;
  margin-top: -30px;
}

.prev-btn img {
  height: 60px;
}

.next-btn {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  padding-right: 20px;
  margin-top: -30px;
}

.next-btn img {
  height: 60px;
}

.partners .row {
  align-items: center !important;
}

.partners img {
  padding: 16px 0px;
  width: 100%;
}

.partners .title-one {
  margin-bottom: 90px;
}

.partners .title-one h2 {
  font-size: 28px;
}

.partners .title-two {
  margin-top: 90px;
}

.partners .title-two h2 {
  font-size: 28px;
}

.partners .partner-img {
  text-align: center;
  padding: 0 0;
}

.contact h1 {
  margin-bottom: 30px;
}

.contact p {
  font-size: 25px;
  margin-bottom: 20px;
}

.contact input[type=text] {
  text-align: center;
  width: 100%;
  max-width: 680px;
  height: 68px;
  font-size: 28px;
  font-family: "CardoItalic";
  font-style: italic;
  background: none;
  border-radius: 10px;
  border: solid 1px #aeb2b4;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.4));
  margin-bottom: 25px;
  color: #b9b9b9;
}

.contact input[type=text]::-moz-placeholder {
  color: #939393;
}

.contact input[type=text]::placeholder {
  color: #939393;
}

.contact .thank-you {
  font-size: 44px;
}

.errorInput::-moz-placeholder {
  color: #f43535 !important;
}

.errorInput::placeholder {
  color: #f43535 !important;
}

.send-btn {
  color: white;
  font-family: "Biko";
  font-size: 28px;
  display: inline-block;
  border-radius: 10px;
  border: solid 1px #485b62;
  padding: 10px 20px;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.4));
  margin-bottom: 10px;
  margin-top: 0px;
  background-color: #272b4e;
}

.send-btn:visited {
  color: white;
}

.send-btn:hover {
  text-decoration: none;
}

.send-btn-disabled {
  color: #353535;
  font-family: "Biko";
  font-size: 28px;
  display: inline-block;
  border-radius: 10px;
  border: solid 1px #353535;
  padding: 10px 20px;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.4));
  margin-bottom: 10px;
  margin-top: 0px;
  background-color: none;
  pointer-events: none;
  cursor: default;
}

.send-btn-disabled:visited {
  color: #6b6e70;
}

.container-cookies {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #000;
  border-top: 1px solid #4e4e5a;
  bottom: 30px;
  left: 50%;
  margin-left: -136px;
  z-index: 999;
  width: 272px;
  height: 56px;
  border: 1px solid #333343;
  border-radius: 12px;
  padding: 0 20px;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.4));
}

.container-cookies h4 {
  font-size: 16px;
  color: #929292;
  margin-bottom: 0px;
}

.container-cookies p {
  font-size: 14px;
  margin: 0;
  color: #b8b8b7;
}

.cookies-btns {
  display: flex;
  margin-left: auto;
  gap: 10px;
}

.cookies-btns .cookies-btn {
  display: flex;
  align-items: center;
  color: #b8b8b7;
  text-decoration: underline;
}

@media only screen and (max-width: 1200px) {
  .logo-btn img {
    width: 100px;
  }
  .container-menu ul li a {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1080px) {
  .container-menu ul li {
    margin-left: 70px;
  }
}
@media only screen and (max-width: 992px) {
  .container-menu ul li {
    margin-left: 50px;
  }
}
@media only screen and (max-width: 880px) {
  .container-section {
    min-height: calc(100dvh - 73px - 90px);
  }
  .container-menu {
    height: 73px;
  }
  .container-menu .inner-container-menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    max-height: -webkit-fill-available !important;
  }
  .container-menu .inner-container-menu ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    max-height: -webkit-fill-available !important;
  }
  .container-menu .inner-container-menu ul li {
    float: inherit;
    width: 100%;
  }
  .container-menu .inner-container-menu ul li a {
    font-size: 24px;
    padding: 16px 0;
  }
  .burger-btn {
    display: block;
  }
  .container-section-work .section-title {
    font-size: 26px;
  }
  .footer-work h3 {
    font-size: 26px;
  }
  .footer-work h3 span {
    font-size: 18px;
  }
  .footer-work p {
    font-size: 12px;
  }
  .partners .title-one h2 {
    font-size: 22px;
  }
  .partners .title-two h2 {
    font-size: 22px;
  }
  .container-menu ul li {
    margin-left: 0;
  }
  .hashtag {
    font-size: 14px;
  }
}
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 36px;
  }
  .container-section {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: calc(100dvh - 73px - 70px);
  }
  .container-menu {
    padding: 20px;
    align-items: flex-start;
  }
  .social {
    padding: 20px;
    height: 70px;
  }
  .como h1 {
    margin-bottom: 15px;
  }
  .como .diagrama p {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .partners .row {
    height: 100vh !important;
    align-items: center !important;
    max-height: -webkit-fill-available !important;
  }
  .splash-text {
    font-size: 32px;
  }
  .como .diagrama-img img {
    margin-top: 30px;
    width: 70%;
  }
  .contact p {
    font-size: 15px;
    margin-bottom: 16px;
  }
  .contact input[type=text] {
    height: 45px;
    max-width: 580px;
    font-size: 16px;
  }
  .contact .thank-you {
    font-size: 38px;
  }
  .send-btn, .send-btn-disabled {
    font-size: 16px;
  }
  .container-section-work .section-title {
    top: 80px;
    left: 20px;
    font-size: 18px;
  }
  .footer-work-titles {
    padding: 10px 20px;
  }
  .footer-work-lines {
    display: none;
  }
  .footer-work-items {
    display: none;
  }
  .footer-work-single-line {
    display: flex;
  }
  .footer-work-single-item {
    display: flex;
  }
  .prev-btn, .next-btn {
    margin-top: -20px;
  }
  .prev-btn img, .next-btn img {
    height: 40px;
  }
}
@media only screen and (max-width: 430px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
  }
  .container {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .splash-text {
    font-size: 28px;
  }
  .br-txt-mobile {
    display: inherit;
  }
  .show-mobile {
    display: block;
  }
  .show-desktop {
    display: none !important;
  }
  .partners {
    max-height: none !important;
  }
  .partners .row {
    height: auto !important;
    align-items: flex-start !important;
  }
  .partners .title-one {
    margin-bottom: 45px;
    margin-top: 16px;
  }
  .partners .title-one h2 {
    font-size: 16px;
  }
  .partners .title-two {
    margin-bottom: 16px;
    margin-top: 45px;
  }
  .partners .title-two h2 {
    font-size: 16px;
  }
  .partners img {
    padding: 8px 0;
  }
  .social img {
    height: 16px;
  }
  .como {
    max-height: inherit !important;
  }
  .como .row {
    max-height: inherit !important;
  }
  .contact .thank-you {
    font-size: 28px;
  }
}
@media only screen and (max-width: 390px) {
  .partners .title-one h2 {
    font-size: 15px;
  }
}
@media (hover: none) {
  .cursor {
    pointer-events: none;
    display: none;
  }
}
@media only screen and (max-height: 800px) and (orientation: landscape) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 36px;
  }
  .logo-btn img {
    width: 100px;
  }
  .container-section {
    padding-top: 0px;
    padding-bottom: 0px;
    height: auto;
  }
  .container-menu ul li a {
    font-size: 18px;
  }
  .contact p {
    font-size: 15px;
    margin-bottom: 16px;
  }
  .contact input[type=text] {
    height: 45px;
    max-width: 580px;
    font-size: 16px;
  }
  .contact .thank-you {
    font-size: 38px;
  }
  .send-btn, .send-btn-disabled {
    font-size: 16px;
  }
  .como .diagrama p {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .como .diagrama-img img {
    width: 70%;
  }
  .partners .title-two {
    margin-top: 16px;
  }
  .partners .title-two h2 {
    font-size: 22px;
  }
  .container-section-work .section-title {
    font-size: 26px;
  }
  .footer-work h3 {
    font-size: 26px;
  }
  .footer-work h3 span {
    font-size: 18px;
  }
  .footer-work p {
    font-size: 14px;
  }
  .hashtag {
    font-size: 14px;
  }
}
@media only screen and (max-height: 430px) and (orientation: landscape) {
  .container-section {
    min-height: calc(100dvh - 73px - 70px);
  }
  .partners .row {
    height: 100vh !important;
    align-items: center !important;
    max-height: -webkit-fill-available !important;
  }
  .container-menu {
    height: 73px;
  }
  .container-menu .inner-container-menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    max-height: -webkit-fill-available !important;
  }
  .container-menu .inner-container-menu ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    max-height: -webkit-fill-available !important;
  }
  .container-menu .inner-container-menu ul li {
    float: inherit;
    width: 100%;
  }
  .container-menu .inner-container-menu ul li a {
    font-size: 24px;
    padding: 8px 0;
  }
  .burger-btn {
    display: block;
  }
  .como .diagrama-img img {
    width: 70%;
  }
}/*# sourceMappingURL=styles.css.map */